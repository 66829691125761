// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import '~js-datepicker/src/datepicker';


.course-link {
    cursor: pointer;
}


.fas {
    color: $blue;
}

button.fas {
    border: none;
    background: none;
}

.v-hidden {
    visibility: hidden;
}

.border-red {
    border-color: red;
}
